<template>
  <div class="main_profile_wrapper">
    <div class="main_info_wrapper mb-5">
      <div class="info">
        <img
          class="img"
          src="../../../assets/new-theme/profile/Image wrap.png"
          alt=""
        />
      </div>
      <div class="info_data">
        <div class="wrap_one">
          <div class="main_user_img_wrapper">
            <img class="user_img" :src="image" alt="user image" />
          </div>
          <div>
            <span class="name d-block"
              >{{ userData.first_name }} {{ userData.last_name }}</span
            >
            <span class="email d-block">{{ userData.email }}</span>
          </div>
        </div>
        <div class="buttons_links" v-if="!$route.params.id">
          <button class="btn_1" @click="switchToPersonalProfile">
            <img
              class="img"
              src="../../../assets/new-theme/profile/edit-05.png"
              alt=""
            />
            <span>{{ localization('Edit your Account') }}</span>
          </button>
          <button class="btn_2" @click="toggleModalShare()">
            <img
              class="img"
              src="../../../assets/new-theme/profile/share-07.png"
              alt=""
            />
            <span>{{localization('Share')}}</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Start Patterns -->
    <UserPattern :userScore="userScore" :visible="true"></UserPattern>
    <!-- End Patterns -->

    <!-- Start Your Learner Type -->
    <div class="learner_wrapper">
      <h2 class="main_title ">{{localization('Your Learner Type')}}</h2>
      <div class="main_learn_card mt-3">
        <div class="mb-3">
          <img
            class="img"
            src="../../../assets/new-theme/profile/Featured icon.png"
            alt=""
          />
        </div>
        <div>
          <span class="pattern_name">{{userData.classification}} Learner</span>
        </div>
        <!-- <p class="mb-0 text">
          The {{userData.classification}} Learner uses three or more Patterns at the Use First
          level.
        </p> -->
        <div class="mt-3 mb-3">
          <!-- <span class="d-block Characteristics mb-3">{{localization('Characteristics')}}</span>
          <ul class="list_items">
            <li class="item_in_list mt-1">
              {{localization('Prefers to lead rather than be led.')}}
            </li>
            <li class="item_in_list mt-1">
              {{localization('Prefers to control plans, ideas, talks, decisions, processes, and outcomes independently.')}}
            </li>
          </ul> -->
          <div class="description_type">
            <p class="text_type" v-if="userData.classification == 'Bridge'">
              {{ localization("The Bridge Learner uses all Patterns at a Use as Needed level. Your Patterns serve a helpful role by leading from the middle. Bridge learners are team catalysts. The Bridge learner can close the gap of understanding between learners who have Use First and Avoid Patterns.") }}
            </p>
            <p class="text_type" v-if="userData.classification == 'Dynamic'">
              <!-- {{ localization("dynamic tool tip") }} -->
              <ul>
                <li class="mb-2">
                  {{localization('Dynamic is the combination of Learning Patterns that uses at least one Pattern at the Use First level, while the remaining ones are any combination of another Use First, Use as Needed, or Avoid.')}}
                </li>
                <li class="mb-2">
                  {{ localization("You experience yourself shifting from the use of one Pattern to another, particularly when you move from your Use First Patterns to those you Avoid.") }}
                </li>
                <li class="mb-2">
                  {{ localization("As a Dynamic learner, you can relate to others well and understand how they use their Learning Patterns.") }}
                </li>
                <li class="mb-2">
                  {{ localization("You can identify when a colleague or supervisor is shifting from one Pattern to another and are able to sense when others in your work setting are having their Pattern needs ignored.") }}
                </li>
              </ul>
            </p>
            <p
              class="text_type"
              v-if="userData.classification == 'Strong-Willed Learner'"
            >
              {{ localization("The Strong-Willed Learner uses three or more Patterns at the Use First level. Strong-Willed learners seek opportunities to lead rather than be led. Your Patterns would have you prefer to control the plan, the ideas, the talks, the decisions, the processes, and the outcomes and do it all yourself. Understanding how your Patterns affect those around you is particularly important when you are a Strong-Willed Learner.") }}
            </p>
          </div>
        </div>
        <!-- <p class="mb-0 text">
          {{localization("It's crucial to understand how your Patterns impact those around you.")}}
        </p> -->
        <!-- <div class="mt-3 learn_more">
          <span class="color more">Learn more</span>
          <img
            class="img"
            src="../../../assets/new-theme/profile/arrow-right.png"
            alt=""
          />
        </div> -->
      </div>
    </div>
    <!-- End Your Learner Type -->

    <!-- Use Your Learning Patterns -->
    <div class="learner_patterns_wrapper">
      <h2 class="main_title ">
        {{localization("Use Your Learning Patterns")}}
      </h2>
      <p class="mb-0 text">
        {{localization("Add your own task and discover what patterns are needed to successfully complete it.")}}
      </p>
      <div class="main_cards mt-3">
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="../../../assets/new-theme/profile/book.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              {{localization("Study-related task")}}
            </span>
            <p class="mb-0 description">
              {{localization("Add new tasks in math, reading, writing, study skills, science, social studies, etc...")}}
            </p>
          </div>
          <div>
            <button class="lci_btn " @click="ToggleTaskModal">
              <img
                class="img"
                src="../../../assets/new-theme/profile/plus.png"
                alt=""
              />
              <span>
                {{localization("Add a new study-related task")}}
              </span>
            </button>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="../../../assets/new-theme/profile/wallet.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              {{localization("Work-related task")}}
            </span>
            <p class="mb-0 description">
              {{localization("Add new tasks in time management, team interaction, meeting preparation, etc...")}}
            </p>
          </div>
          <div>
            <button class="lci_btn " @click="ToggleTaskModal2">
              <img
                class="img"
                src="../../../assets/new-theme/profile/plus.png"
                alt=""
              />
              <span>
                {{localization("Add a new work-related task")}}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Use Your Learning Patterns -->

    <!-- Use Your Personal Patterns -->
    <div class="your_personal_wrapper" v-if="userData.role != 'basic' && userData.role != 'institution basic'">
      <h2 class="main_title ">
        {{localization("Build Your Personal Work Profile (PWP)")}}
      </h2>
      <p class="mb-0 text">
        {{localization("And see if the job is the right fit for you!")}}
      </p>
      <div class="mt-3">
        <button class="lci_btn " @click="switchToPWP">
          <span>
            {{localization("Get strategies!")}}
          </span>
          <img
            class="img"
            src="../../../assets/new-theme/profile/arrow-right-white.png"
            alt=""
          />
        </button>
      </div>
    </div>

    <!--  -->
    <!-- <div class="video_player_wrapper">
      <h2 class="main_title mt-0">Learning Material</h2>
      <p class="mb-0 text">
        Discover a step-by-step videos about learning patterns and the different learner types.
      </p>
      <div class="mt-3 text-center">
        <div class="d-flex justify-content-center">
          <img
            class="img"
            src="@/assets/new-theme/profile/video_player.png"
            alt=""
          />

        </div>
        <div class="d-flex justify-content-center mt-3">

            <button class="discover_video_btn">
          <img
            class="img"
            src="@/assets/new-theme/profile/video_icon.png"
            alt=""
          />
          <span>Discover other videos</span>
        </button>
        </div>

      </div>
    </div> -->

    <!-- Personalize Your Profile-->
    <div class="personalize_profile" v-if="userData.role != 'basic' && userData.role != 'institution basic' ">
      <div>
        <div class="item_wrap">
          <h2 class="main_title white_text mb-0 mt-0" >
            {{localization("Build Your Personal Work Profile (PWP) Recommended!")}}
          </h2>
        </div>
        <p class="mb-0 text white_text mt-2">
          {{localization("And share with others how you learn, work, and interact with others.")}}
        </p>
      </div>
      <div class="last_section">
        <img
          role="button"
          @click="switchToPWP"
          class="img"
          src="@/assets/new-theme/profile/arrow-right.png"
          alt=""
        />
      </div>
    </div>

    <!--  Start Share -->
    <ShareModal
      :showHideModalShare.sync="showHideModalShare"
      @close="toggleModalShare"
      @submit="submitShared"
      :title="`${localization('Share your profile')}`"
      :description="`${localization('Share your personalized profile and learning patterns score with others to let them know how you learn, work, and interact with others.')}`"
    ></ShareModal>
    <!--  End Share -->
    <!-- Task Modal // in school task -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      @close="resetForm"
      :close-on-click-modal="false"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/file.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title">
          {{ localization("Add a new study-related task") }}
        </h3>
        <p class="description">
          {{ localization("Add your own task and discover what patterns are needed to successfully complete it.") }}
        </p>
      </div>

      <!-- Task Form -->
      <el-form :model="task" :rules="rules" ref="taskForm" class="task-form">
        <div class="flex justify-content-between" style="gap: 10px">
          <div class="flex-grow-1">
            <!-- Task Name -->
            <el-form-item prop="name">
              <AppInputLabel :text="`${localization('Name your task')}`" :star="true"></AppInputLabel>
              <el-input
                class="new_theme_custom_input"
                v-model="task.name"
                :placeholder="`${localization('Give your task a name')}`"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex-grow-1">
            <!-- Task Topic -->
            <el-form-item prop="topic_id">
              <AppInputLabel :text="`${localization('Task category')}`" :star="true"></AppInputLabel>
              <el-select
                v-model="task.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in standardTopicsData"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <!-- Task Description -->
        <el-form-item prop="description">
          <AppInputLabel :text="`${localization('Task description')}`" :star="true"></AppInputLabel>
          <el-input
            class="new_theme_custom_input input_with_custom_text_area"
            type="textarea"
            v-model="task.description"
            :placeholder="`${localization('Add task description')}`"
            clearable
          />
        </el-form-item>
      </el-form>
      <div class="or_modal mb-3">
        {{ localization("or") }}
      </div>
      <!-- File Upload Section -->
      <div class="file-upload">
        <el-upload
          class="upload-demo"
          drag
          action=""
          :on-change="handleUploadSuccess"
          :before-upload="beforeUpload"
          :file-list="fileList"
          :auto-upload="false"
          :on-remove="handleRemove"
          :show-file-list="false"
          accept="image/apng, image/jpeg, image/png, image/webp"
          :disabled="loading"
        >
          <div v-if="!loading">
            <img src="@/assets/new-theme/upload.png" alt="upload" />
            <div class="el-upload__text">
              <span class="click_upload">
                  {{ localization("Click to upload") }}
              </span>
                  {{ localization("or drag and drop") }}
            </div>
            <div class="el-upload__tip">
              Supported: PNG, JPG, (Max 5MB)
            </div>
          </div>
          <div
            class="d-flex spin_modal"
            v-loading="loading"
            element-loading-text="waiting to extract photo..."
          ></div>
        </el-upload>
      </div>

      <!-- Display Uploaded Files with Delete Option -->
      <div v-if="fileList && fileList.length > 0" class="uploaded-files">
        <ul>
          <li v-for="file in fileList" :key="file.name" class="file-item">
            <div class="d-flex" style="gap: 10px">
              <div>
                <img src="@/assets/new-theme/file.png" alt="" />
              </div>
              <div>
                <span class="file-name">{{ file.name }}</span>
                <span class="d-block file_size"
                  >{{ (file.size / 1024).toFixed(2) }} KB</span
                >
              </div>
            </div>
            <i class="el-icon-delete file-delete" @click="removeFile(file)"></i>
          </li>
        </ul>
      </div>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-4">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          {{ localization("Cancel") }}
        </button>
        <button @click="submitForm" class="lci_btn save  lci_btn_modal_cancel">
          {{ localization("Confirm") }}
        </button>
      </div>
    </el-dialog>
    <!-- End Add Task Modal // in school task -->

    <!-- Task Modal // at work-->
    <el-dialog
      title=""
      :visible.sync="dialogVisible2"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      @close="resetForm2"
      :close-on-click-modal="false"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/file.png" alt="file" />
          <img
            @click="resetForm2"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title">
          {{ localization("Add a new work-related task") }}
        </h3>
        <p class="description">
          {{ localization("Add your own task and discover what patterns are needed to successfully complete it.") }}
        </p>
      </div>

      <!-- Task Form -->
      <el-form :model="task2" :rules="rules2" ref="taskForm2" class="task-form">
        <div class="flex justify-content-between" style="gap: 10px">
          <div class="flex-grow-1">
            <!-- Task Name -->
            <el-form-item prop="name">
              <AppInputLabel :text="`${localization('Name your task')}`" :star="true"></AppInputLabel>
              <el-input
                class="new_theme_custom_input"
                v-model="task2.name"
                :placeholder="`${localization('Give your task a name')}`"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex-grow-1">
            <!-- Task Topic -->
            <el-form-item prop="topic_id">
              <AppInputLabel :text="`${localization('Task category')}`" :star="true"></AppInputLabel>
              <el-select
                v-model="task2.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in atWorkTasksTopics"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <!-- Task Description -->
        <el-form-item prop="description">
          <AppInputLabel :text="`${localization('Task description')}`" :star="true"></AppInputLabel>
          <el-input
            class="new_theme_custom_input input_with_custom_text_area"
            type="textarea"
            v-model="task2.description"
            :placeholder="`${localization('Add task description')}`"
            clearable
          />
        </el-form-item>
      </el-form>
      <div class="or_modal mb-3">
        {{ localization("or") }}
      </div>
      <!-- File Upload Section -->
      <div class="file-upload">
        <el-upload
          class="upload-demo"
          drag
          action=""
          :on-change="handleUploadSuccess"
          :before-upload="beforeUpload"
          :file-list="fileList"
          :auto-upload="false"
          :on-remove="handleRemove"
          :show-file-list="false"
          accept="image/apng, image/jpeg, image/png, image/webp"
          :disabled="loading"
        >
          <div v-if="!loading">
            <img src="@/assets/new-theme/upload.png" alt="upload" />
            <div class="el-upload__text">
              <span class="click_upload">{{ localization("Click to upload") }}</span> {{ localization("or drag and drop") }}
            </div>
            <div class="el-upload__tip">
              Supported: PNG, JPG, (Max 5MB)
            </div>
          </div>
          <div
            class="d-flex spin_modal"
            v-loading="loading"
            element-loading-text="waiting to extract photo..."
          ></div>
        </el-upload>
      </div>

      <!-- Display Uploaded Files with Delete Option -->
      <div v-if="fileList && fileList.length > 0" class="uploaded-files">
        <ul>
          <li v-for="file in fileList" :key="file.name" class="file-item">
            <div class="d-flex" style="gap: 10px">
              <div>
                <img src="@/assets/new-theme/file.png" alt="" />
              </div>
              <div>
                <span class="file-name">{{ file.name }}</span>
                <span class="d-block file_size"
                  >{{ (file.size / 1024).toFixed(2) }} KB</span
                >
              </div>
            </div>
            <i class="el-icon-delete file-delete" @click="removeFile(file)"></i>
          </li>
        </ul>
      </div>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-4">
        <button @click="resetForm2" class="lci_btn_modal_cancel ">
          {{ localization("Cancel") }}
        </button>
        <button @click="submitForm2" class="lci_btn save  lci_btn_modal_cancel">
          {{ localization("Confirm") }}
        </button>
      </div>
    </el-dialog>
    <!-- End Add Task Modal // at work -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "ProfileView",
  data() {
    return {
      showHideModalShare: false,
      dialogVisible: false,
      dialogVisible2: false,
      task: {
        name: "",
        topic_id: "",
        description: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please enter the task name",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please enter the task topic",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please enter a task description",
            trigger: "blur"
          }
        ]
      },
      loading: false,
      fileList: [],
      task2: {
        name: "",
        topic_id: "",
        description: ""
      },
      rules2: {
        name: [
          {
            required: true,
            message: "Please enter the task name",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please enter the task topic",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please enter a task description",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userData() {
      return this.$store.state.user.user;
    },
    image() {
      if (this.userData.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.userData.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    },
    standardTopicsData() {
      return this.$store.getters["topics/getStandardTopics"];
    },
    atWorkTasksTopics() {
      return this.$store.getters["user/getAtWorkTasksTopics"];
    }
  },
  methods: {
    // Get User Data And Set User Score
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute

        this.selected = this.userData.selected_attributes;
        if(this.userData.sequence){
        }else {
          this.$router.push( '/new-theme/instructions' )
        }
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    // Toggle Modal User Type
    toggleModalShare() {
      this.showHideModalShare = !this.showHideModalShare;
    },
    // Start Extract Photo => in school task
    ToggleTaskModal() {
      this.dialogVisible = !this.dialogVisible;
    },

    handleUploadSuccess(file, fileList) {
      this.fileList = fileList;
      this.handle_upload_file_img(file);
    },

    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("File size cannot exceed 5MB.");
      }
      return isLt5M;
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    removeFile(file) {
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    handle_upload_file_img(file) {
      this.loading = true;
      this.description_photo = file.raw;
      let payload = new FormData();
      payload.append("photo", file.raw);
      this.$store
        .dispatch("user/get_ocr_photo_description", payload)
        .then(response => {
          this.task.description = response.photo_description;
          this.loading = false;
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Please double check the extracted text!")}`,
            type: "success",
            confirmButtonText: `${this.localization("Ok")}`,
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
      this.fileList = [];
      this.loading = false;
    },
    resetForm() {
      this.$refs.taskForm.resetFields();
      this.fileList = [];
      this.dialogVisible = false;
      // this.$v.$reset();
    },
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/add_task", {
              query: this.task
            })
            .then(response => {
              this.dialogVisible = false;

              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });

              this.$router.push(`/new-theme/tasks/${response.task.id}`);
            })
            .catch(_ => {
              this.dialogVisible = false;
            });

          // Implement strategy generation logic
        } else {
          // this.$message.success("Form is valid. Strategies will be generated.");
          // this.$message.error("Please fill out all required fields.");
          return false;
        }
      });
    },

    submitShared(sharedEmails) {
      let payload = {
        emails: sharedEmails
      };
      

      this.$store.dispatch("user/sharing_profile_task", payload).then(() => {
        this.showHideModalShare = !this.showHideModalShare;
        Swal.fire({
          title: "",
          text: `${this.localization("Done")}`,
          confirmButtonText: `${this.localization("Ok")}`,
          icon: "success",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        });
      });
    },
    // End Extract Photo  => in school task

    //  Start At Work Task
    ToggleTaskModal2() {
      this.dialogVisible2 = !this.dialogVisible2;
    },
    resetForm2() {
      this.$refs.taskForm2.resetFields();
      this.fileList = [];
      this.dialogVisible2 = false;
      // this.$v.$reset();
    },
    submitForm2() {
      this.$refs.taskForm2.validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/handleAddWorkTask", {
              query: this.task2
            })
            .then(response => {
              this.dialogVisible2 = false;

              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });

              this.$router.push(`/new-theme/at-work/${response.task.id}`);
            })
            .catch(_ => {
              this.dialogVisible2 = false;
            });
        } else {
          // this.$message.success("Form is valid. Strategies will be generated.");
          // this.$message.error("Please fill out all required fields.");
          return false;
        }
      });
    },
    // End At Work Task
    // switch t0 personal profile
    switchToPersonalProfile() {
      this.$router.push("/new-theme/personalize-profile");
    },
    // End switch to personal profile
    switchToPWP() {
      this.$router.push("/new-theme/pwp");
    }
  },
  mounted() {
    this.$store.dispatch("topics/handlerStandardTopics");
    this.$store.dispatch("user/handlerWorkTopics");
    this.getMainUserData()
  }
};
</script>

<style lang="scss" scoped>
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;

  .main_title {
    color: #101828;
    font-weight: 600;
    font-size: 20px;
    &.white_text {
      color: #fff;
    }
  }

  // Start Your Learner Typ
  .learner_wrapper {
    margin-top: 40px;
    .main_learn_card {
      box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
      border-radius: 8px;
      border: 1px solid #eaecf0;
      padding: 24px;
      .pattern_name {
        font-weight: 500;
        font-size: 18px;
        color: #101828;
        margin-bottom: 10px;
        display: block;
      }
      .text {
        color: #667085;
        font-weight: 400;
        font-size: 16px;
      }
      .Characteristics {
        font-weight: 400;
        font-size: 16px;
        color: #667085;
      }
      .list_items {
        margin-bottom: 0 !important;
        margin-left: 0;
      }
      .item_in_list {
        font-weight: 400;
        font-size: 15px;
        color: #667085;
      }
    }
    .learn_more {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .more {
      font-weight: 500;
      font-size: 16px;
    }
  }
  // Use Your Learning Patterns
  .learner_patterns_wrapper {
    margin-top: 40px;
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .main_cards {
      display: flex;
      justify-content: space-between;
      gap: 4%;
      .card_item {
        border-radius: 8px;
        border: 1px solid #eaecf0;
        padding: 24px;
        width: calc(96% / 2);

        .title {
          color: #101828;
          font-weight: 600;
          font-size: 18px;
        }
        .description {
          color: #667085;
          font-weight: 400;
          font-size: 16px;
        }
        .lci_btn {
          background-color: #f2f4f7;
          color: #6941c6;
          border-radius: 8px;
          padding: 12px 20px;
          border: 1px solid #eaecf0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }

  .your_personal_wrapper {
    margin-top: 40px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    padding: 24px;
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .lci_btn {
      border-radius: 8px;
      padding: 12px 20px;
      border: 1px solid #eaecf0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 240px;
      font-weight: 500;
      font-size: 16px;
      background-color: #7f56d9;
      color: #fff;
      &.save {
      }
    }
  }
  .video_player_wrapper {
    padding: 24px;
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }

.discover_video_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px 20px;
  border: 2px solid #d0d5dd;
  color: #667085;
  min-width: 200px;
  height: 48px;
}
  }
  .personalize_profile {
    margin-top: 40px;
    border-radius: 8px;
    background-color: #7f56d9;
    color: #fff;
    padding: 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
    }
    .item_wrap {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .recommended {
      background-color: #fff;
      color: #7f56d9;
      font-size: 16px;
      font-weight: 500;

      padding: 2px 8px 2px 8px;
      border-radius: 16px;
    }
    .last_section {
      background-color: #fff;
      height: 32px;
      width: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .color {
    color: #6941c6;
  }
}

::v-deep .input_with_custom_text_area .el-textarea__inner {
  word-break: break-word !important; /* Ensures long words break */
  overflow-wrap: break-word !important; /* Ensures text wraps properly */
  white-space: pre-wrap !important; /* Preserves line breaks */
}

</style>
